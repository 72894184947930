html{
  margin: 0;
  background-color: #f1f1f1;
}
body{
  margin: 0;
  background-color: #f1f1f1;
  overflow-x: hidden;
  overflow-y: auto;
}

*{
   box-sizing: border-box;
}
html, body{
   margin: 0px;
   padding: 0px;
}

.banner{
   position: relative;
}
