html, body{
}

body *{
  /* -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; */
  /* pointer-events: none; */
}
body img{
  /* pointer-events: none; */
  cursor: default;
}
body .previewLabel,
body .info{
  z-index: 1;
  cursor: default;
}
body .previewLabel > *,
body .info > *{
  cursor: grab;
  z-index: 5;
  position: relative;
}
.refProductImage{
  cursor: grab;
  position: relative;
  z-index: 4;
}
body .moveable-control.moveable-origin {
  display: none;
}
.layoutArea{
  width: 100%;
  position: relative;
}
.sizeAuto .layoutArea{
  height: calc(calc(220px - 33px) - 12px) !important;
}
.sizeCamion .layoutArea{
  height: calc(calc(328px - 33px) - 12px) !important;
}
.layoutArea > *{
  position: absolute;
}
.layoutFooter{
  width: 100%;
  height: 33px;
  position: absolute;
  top: calc(calc(220px - 33px) - 12px);
  z-index: 2;
}
.sizeAuto .layoutFooter{
  top: calc(calc(220px - 33px) - 12px);
}
.sizeCamion .layoutFooter{
  top: calc(calc(328px - 33px) - 12px);
}

.banner{
   width: 890px;
   font-family: 'Roboto', sans-serif;   
}
.sizeAuto.banner{
  min-height: 220px;
  height: 220px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 220px, #d2dfe0 220px, #d2dfe0 100%);
}
.sizeCamion.banner{
  min-height: 328px;
  height: 328px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 328px, #d2dfe0 328px, #d2dfe0 100%);
}

.banner::before{
  border: 2px solid #000000;
  border-radius: 10px;
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 4px);
  height: calc(220px - 4px);
}
.sizeAuto.banner::before{
  height: calc(220px - 4px);
}
.sizeCamion.banner::before{
  height: calc(328px - 4px);
}

table.imageTable{
  width: calc(100% - 24px);
  height: calc(calc(100% - 33px) - 24px);
  border-collapse: collapse;
  z-index: 999;
  position: absolute;
}
table.descriptionTable{
  position: absolute;
  top: calc(calc(220px - 33px) - 12px);
  width: 100%;
  height: 33px;
  border-collapse: collapse;
}
.sizeAuto table.descriptionTable{
  top: calc(calc(220px - 33px) - 12px);
}
.sizeCamion table.descriptionTable{
  top: calc(calc(328px - 33px) - 12px);
}

.infoRow td{
  height: 16px;
  /* padding: 0px 14px; */
}
.previewRow td{
  position: relative;
  vertical-align: top;
  height: 100%;
  text-align: center;
}
.previewRow td img{
  display: inline-block;
}
.descriptionRow td{
  height: 33px;
  padding: 0px 9px;
}



.info{
  position: relative;
}
.infoElement{
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.infoIcon{
  width: 28px;
  height: 28px !important;
  margin-right: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.infoText{
  color: #000000;
  font-size: 10px;
  font-weight: bold;
}

.description{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.descriptionLeft{
  text-align: left;
}
.descriptionRight{
  text-align: right;
}
.descriptionPrimary{
  color: #000000;
  font-size: 19.2px;
  font-weight: bold;
}
.descriptionLeft .descriptionPrimary{
  margin-right: 10px;
}
.descriptionSecondary{
  color: #71706f;
  font-size: 9px;
}
.styleCode{
  color: #71706f;
  font-size: 9px;
}
.styleYear{
  color: #000000;
  font-size: 19px;  
  font-weight: 400 !important;
}

.previewLabel{
  position: absolute;
  left: 0px;
  width: 100%;

  text-align: center;
}
.previewLabelIndependent{
  position: relative;
  text-align: center;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.previewLabelPrimary{  
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  /* padding-right: 50px; */
}
.previewLabelSecondary{
  font-size: 9px;
  color: #000000;
  /* padding-right: 50px; */
}
.previewLabelNotes{
  color: #71706f;
  font-size: 9px;
  white-space: nowrap;
  font-weight: bold;
  /* padding-right: 50px; */
}

.refProductImage:hover,
.infoElement:hover,
.previewLabel > *:hover{
  background: #009DE233;
}


/* v temp v */
.banner{
  transform-origin: top left;
}


.infoIcon{
  /* background-color: #dedede; */
}
/*
.infoRow:nth-child(1) td:nth-child(1){
  background: #e8d9a6;
}
.infoRow:nth-child(1) td:nth-child(2){
  background: #ffd9d9;
}
.infoRow:nth-child(1) td:nth-child(3){
  background: #c2caca;
}
.infoRow:nth-child(1) td:nth-child(4){
  background: #ff8f8f;
}
.infoRow:nth-child(2) td:nth-child(1){
  background: #e8ebb4;
}
.infoRow:nth-child(2) td:nth-child(2){
  background: #ffebeb;
}
.infoRow:nth-child(2) td:nth-child(3){
  background: #c2dada;
}
.infoRow:nth-child(2) td:nth-child(4){
  background: #ff9b9b;
}
.infoRow:nth-child(3) td:nth-child(1){
  background: #f8fbc4;
}
.infoRow:nth-child(3) td:nth-child(2){
  background: #fffbfb;
}
.infoRow:nth-child(3) td:nth-child(3){
  background: #d2eaea;
}
.infoRow:nth-child(3) td:nth-child(4){
  background: #ffbbbb;
}
.previewRow td:nth-child(1){
  background: #e8ffc3;
}
.previewRow td:nth-child(2){
  background: #fff3f3;
}
.previewRow td:nth-child(3){
  background: #c2eded;
}
.previewRow td:nth-child(4){
  background: #ffa8a8;
}

.descriptionRow td{
  background: #f1c232;
}
*/
/* ^ temp ^ */
