.style-1{
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  white-space: pre;
}
.style-2{
  font-size: 9px;
  color: #000000;
  white-space: pre;
}
.style-3{
  font-size: 11.2px;
  color: #71706f;
  line-height: 6.7px;
  white-space: pre;
}
.style-4{
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  white-space: pre;
}
.style-5{
  font-size: 11px;
  font-weight: bold;
  color: #000000;
  white-space: pre;
}
.style-6{
  color: #000000;
  font-size: 10px;
  font-weight: bold;
  white-space: pre;
}

.style-name{
  position: absolute;
  bottom: 6px;
  left: 9px;
  color: #000000;
  font-size: 19.2px;
  font-weight: bold;
  white-space: pre;
}
.style-description{
  position: absolute;
  bottom: 6px;
  right: 9px;
  color: #000000;
  font-size: 19px;
  white-space: pre;
}
.style-code{
  position: absolute;
  bottom: 28px;
  right: 10px;
  color: #71706f;
  font-size: 9px;
  white-space: pre;
}



.style-n{
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 1px solid #000000;
  position: relative;
  top: -3px;
  background: #000000;
  box-sizing: border-box;
}
.style-r{
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 1px solid #000000;
  background: #000000;
  position: relative;
  top: 1px;
  right: -1px;
  box-sizing: border-box;
}

.style-r::after{
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 1px solid #000000;
  background: #ffffff;
  position: relative;
  top: -5px;
  right: 2px;
  box-sizing: border-box;
}

.style-o{
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 1px solid #000000;
  position: relative;
  top: -3px;
  background: #ffffff;
  box-sizing: border-box;
}

.style-t{
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 1px solid #000000;
  position: relative;
  top: -3px;
  background: #000000;
  box-sizing: border-box;
	transform:rotate(45deg) skew(10deg, 10deg);
}

.style-i::after{
  content: "*";
  display: inline-block;
  width: 5px;
  height: 5px;
  position: relative;
  top: -4px;
  font-size: 14px;
  font-weight: 300;
}
